import React from 'react';
import ProProjectsInserts from '../ProProjectsInserts';
import PersoProjectsInserts from '../PersoProjectsInserts';

const ProjectsPageInserts = () => {
  return (
    <div>
      <PersoProjectsInserts />
    </div>
  );
};

export default ProjectsPageInserts;
